<template>
    <Setup-Step
        @next="f => f()"
        :canNavigate="false"
        hideSkip
        hideYesNo
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        :stage.sync="stage"
        step="courier-order-assign"
        title="Assign To Journeys">
        <template v-slot:body>
            <BT-Courier-Cycle-Header showTrimmings highlightAssign title="Courier Pipeline: Assign To Journeys" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="courier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Assign To Journeys</v-subheader>

                        <p>
                            Movements contain release and delivery information and can be assigned to a journey for a driver to complete.  
                            You can configure BlitzIt Web to try do this automatically or to await for user input.  
                            This can be set globally.
                        </p>

                        <v-row>
                            <v-col cols="4">
                                <BT-Square-Check
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.journeyAssignmentTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.journeyAssignmentTrigger" />
                            </v-col>
                        </v-row>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Order-Assign-Setup',
    components: {
        BTCourierCycleHeader: () => import('~components/BT-Courier-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>